import React from "react";
import "../success/success.css";
import Infinitescroll from "../infinitescroll/infinitescroll";
import Countup from "react-countup"

const Success = () => {
  return (
    <div className="container success-container">
      <h1>Get Acquainted with <br/> Kuaralabs' Success </h1>
      <div className="success-general-side">
        <div className="research-partners">
          <h5>Research Partners</h5>
          <h1>+
          <Countup
          delay={5}
          end={10}
          duration={5}
          />
          </h1>
        </div>

        <div className="finished-quality-projects">
          <h5>Finished Quality Projects</h5>
          <h1>+
          <Countup
          delay={5}
          end={20}
          duration={5}
          />
          </h1>
        </div>

        <div className="satisfied-clients">
          <h5>Satisfied Clients</h5>
          <h1>+
          <Countup
          delay={5}
          end={5}
          duration={5}
          />
          </h1>
        </div>
      </div>
        <Infinitescroll></Infinitescroll>
    </div>
  );
};

export default Success;
