import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';

const GlobeComponent = () => {
  const containerRef = useRef();
  const canvas3DRef = useRef();
  const canvas2DRef = useRef();
  const popupRef = useRef();

  useEffect(() => {
    let renderer, scene, camera, rayCaster, controls, group;
    let overlayCtx = canvas2DRef.current.getContext('2d');
    let coordinates2D = [0, 0];
    let pointerPos;
    let clock, mouse, pointer, globe, globeMesh;
    let popupVisible;
    let earthTexture, mapMaterial;
    let popupOpenTl, popupCloseTl;
    let dragged = false;

    initScene();
    window.addEventListener('resize', updateSize);

    async function initScene() {
      renderer = new THREE.WebGLRenderer({ canvas: canvas3DRef.current, alpha: true });
      renderer.setPixelRatio(2);

      scene = new THREE.Scene();
      camera = new THREE.OrthographicCamera(-1.1, 1.1, 1.1, -1.1, 0, 3);
      camera.position.z = 1.1;

      rayCaster = new THREE.Raycaster();
      rayCaster.far = 1.15;
      mouse = new THREE.Vector2(-1, -1);
      clock = new THREE.Clock();

      createOrbitControls();

      popupVisible = false;

      const [vertexShader, fragmentShader] = await Promise.all([
        fetch('/shaders/vertex-shader-map.glsl').then(res => res.text()),
        fetch('/shaders/fragment-shader-map.glsl').then(res => res.text())
      ]);

      new THREE.TextureLoader().load('https://ksenia-k.com/img/earth-map-colored.png', (mapTex) => {
        earthTexture = mapTex;
        earthTexture.repeat.set(1, 1);
        createGlobe(vertexShader, fragmentShader);
        createPointer();
        createPopupTimelines();
        addCanvasEvents();
        updateSize();
        render();
      });
    }

    function createOrbitControls() {
      controls = new OrbitControls(camera, canvas3DRef.current);
      controls.enablePan = false;
      controls.enableZoom = false;
      controls.enableDamping = true;
      controls.minPolarAngle = 0.4 * Math.PI;
      controls.maxPolarAngle = 0.4 * Math.PI;
      controls.autoRotate = true;

      let timestamp;
      controls.addEventListener('start', () => {
        timestamp = Date.now();
      });
      controls.addEventListener('end', () => {
        dragged = Date.now() - timestamp > 600;
      });
    }

    function createGlobe(vertexShader, fragmentShader) {
      const globeGeometry = new THREE.IcosahedronGeometry(1, 22);
      mapMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        uniforms: {
          u_map_tex: { type: 't', value: earthTexture },
          u_dot_size: { type: 'f', value: 10 },
          u_pointer: { type: 'v3', value: new THREE.Vector3(0.0, 0.0, 1.0) },
          u_time_since_click: { value: 0 },
        },
        alphaTest: false,
        transparent: true,
      });

      globe = new THREE.Points(globeGeometry, mapMaterial);
      scene.add(globe);

      globeMesh = new THREE.Mesh(
        globeGeometry,
        new THREE.MeshBasicMaterial({
          color: 0x222222,
          transparent: true,
          opacity: 0.05,
        })
      );
      scene.add(globeMesh);
    }

    function createPointer() {
      const geometry = new THREE.SphereGeometry(0.04, 16, 16);
      const material = new THREE.MeshBasicMaterial({
        color: 0x00000,
        transparent: true,
        opacity: 0,
      });
      pointer = new THREE.Mesh(geometry, material);
      scene.add(pointer);
    }

    function updateOverlayGraphic() {
      let activePointPosition = pointer.position.clone();
      activePointPosition.applyMatrix4(globe.matrixWorld);
      const activePointPositionProjected = activePointPosition.clone();
      activePointPositionProjected.project(camera);
      coordinates2D[0] = (activePointPositionProjected.x + 1) * containerRef.current.offsetWidth * 0.5;
      coordinates2D[1] = (1 - activePointPositionProjected.y) * containerRef.current.offsetHeight * 0.5;

      const matrixWorldInverse = controls.object.matrixWorldInverse;
      activePointPosition.applyMatrix4(matrixWorldInverse);

      if (activePointPosition.z > -1) {
        if (popupVisible === false) {
          popupVisible = true;
          showPopupAnimation(false);
        }

        let popupX = coordinates2D[0];
        popupX -= activePointPositionProjected.x * containerRef.current.offsetWidth * 0.3;

        let popupY = coordinates2D[1];
        const upDown = activePointPositionProjected.y > 0.6;
        popupY += upDown ? 20 : -20;

        gsap.set(popupRef.current, {
          x: popupX,
          y: popupY,
          xPercent: -35,
          yPercent: upDown ? 0 : -100,
        });

        popupY += upDown ? -5 : 5;
        const curveMidX = popupX + activePointPositionProjected.x * 100;
        const curveMidY = popupY + (upDown ? -0.5 : 0.1) * coordinates2D[1];

        drawPopupConnector(coordinates2D[0], coordinates2D[1], curveMidX, curveMidY, popupX, popupY);
      } else {
        if (popupVisible) {
          popupOpenTl.pause(0);
          popupCloseTl.play(0);
        }
        popupVisible = false;
      }
    }

    function addCanvasEvents() {
      containerRef.current.addEventListener('mousemove', (e) => {
        updateMousePosition(e.clientX, e.clientY);
      });

      containerRef.current.addEventListener('click', (e) => {
        if (!dragged) {
          updateMousePosition(
            e.targetTouches ? e.targetTouches[0].pageX : e.clientX,
            e.targetTouches ? e.targetTouches[0].pageY : e.clientY
          );

          const res = checkIntersects();
          if (res.length) {
            pointerPos = res[0].face.normal.clone();
            pointer.position.set(res[0].face.normal.x, res[0].face.normal.y, res[0].face.normal.z);
            mapMaterial.uniforms.u_pointer.value = res[0].face.normal;
            popupRef.current.innerHTML = cartesianToLatLong();
            showPopupAnimation(true);
            clock.start();
          }
        }
      });

      function updateMousePosition(eX, eY) {
        mouse.x = ((eX - containerRef.current.offsetLeft) / containerRef.current.offsetWidth) * 2 - 1;
        mouse.y = -((eY - containerRef.current.offsetTop) / containerRef.current.offsetHeight) * 2 + 1;
      }
    }

    function checkIntersects() {
      rayCaster.setFromCamera(mouse, camera);
      const intersects = rayCaster.intersectObject(globeMesh);
      if (intersects.length) {
        document.body.style.cursor = 'pointer';
      } else {
        document.body.style.cursor = 'auto';
      }
      return intersects;
    }

    function render() {
      mapMaterial.uniforms.u_time_since_click.value = clock.getElapsedTime();
      checkIntersects();
      if (pointer) {
        updateOverlayGraphic();
      }
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }

    function updateSize() {
      const minSide = 0.65 * Math.min(window.innerWidth, window.innerHeight);
      containerRef.current.style.width = minSide + 'px';
      containerRef.current.style.height = minSide + 'px';
      renderer.setSize(minSide, minSide);
      camera.updateProjectionMatrix();
    }

    function createPopupTimelines() {
      popupOpenTl = gsap.timeline({ paused: true })
        .fromTo(
          pointer.material,
          {
            duration: 0.2,
            opacity: 0,
          },
          {
            duration: 0.2,
            opacity: 1,
          },
          1,
        )
        .fromTo(
          popupRef.current,
          {
            duration: 0.5,
            opacity: 0,
            display: 'none',
          },
          {
            duration: 0.5,
            opacity: 1,
            display: 'block',
          },
          0,
        );

      popupCloseTl = gsap.timeline({ paused: true })
        .fromTo(
          pointer.material,
          {
            duration: 0.2,
            opacity: 1,
          },
          {
            duration: 0.2,
            opacity: 0,
          },
        )
        .fromTo(
          popupRef.current,
          {
            duration: 0.5,
            opacity: 1,
            display: 'block',
          },
          {
            duration: 0.5,
            opacity: 0,
            display: 'none',
          },
          0,
        );
    }
    function cartesianToLatLong() {
      const radius = 1; // Assuming unit sphere
    
      // Calculate latitude and longitude in radians
      const latRad = Math.asin(pointerPos.y / radius);
      const lonRad = Math.atan2(pointerPos.z, pointerPos.x);
    
      // Convert radians to degrees
      const lat = latRad * (180 / Math.PI);
      const lon = lonRad * (180 / Math.PI);
    
      // Format to six decimal places
      return `${lat.toFixed(6)}, ${lon.toFixed(6)}`;
    }

    function showPopupAnimation(open) {
      if (open) {
        popupCloseTl.pause(0);
        popupOpenTl.play(0);
      } else {
        popupCloseTl.play(0);
      }
    }

    function drawPopupConnector(startX, startY, midX, midY, endX, endY) {
      const controlX = 0.7 * midX + 0.3 * endX;
      const controlY = 0.7 * midY + 0.3 * endY;

      overlayCtx.clearRect(0, 0, canvas2DRef.current.width, canvas2DRef.current.height);
      overlayCtx.beginPath();
      overlayCtx.moveTo(startX, startY);
      overlayCtx.quadraticCurveTo(controlX, controlY, endX, endY);
      overlayCtx.strokeStyle = '#fff';
      overlayCtx.lineWidth = 2;
      overlayCtx.stroke();
    }

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <div id="container" ref={containerRef}>
      <canvas ref={canvas3DRef} />
      <canvas ref={canvas2DRef} />
      <div id="popup" ref={popupRef}></div>
      <style jsx>{`
        #container {
          position: relative;
        }
        canvas {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }
        #popup {
          position: absolute;
          color: #111;
          font-family: 'Syne', Arial, sans-serif;
          font-size: 16px;
          background: white;
          padding: 10px;
          border-radius: 5px;
          filter: drop-shadow(0px 0px 3px #35c587);
        }
      `}</style>
    </div>
  );
};

export default GlobeComponent;
