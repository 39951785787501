import React from 'react';
import GlobeComponent from '../globe/globe.jsx';
import './intro.css'


const Intro = () => {
    return (
        <main className="main">
            <div className="textbox">
                <h1>Welcome to Kuaralabs!</h1>
                <p>Kuaralabs provides the best services in its field to every customer <br/> in the world with the services and products it offers.</p>
            </div>
            <div className="globe-container">
                <GlobeComponent />
            </div>
        </main>
    );
};

export default Intro;
