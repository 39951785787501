import React from 'react'
import '../home/home.css'
import Communication from '../../components/communication/communication'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
const Contactpage = () => {
  return (
    <div>
    <Header/>
      <div className="communication-wrapper">
        <Communication/>
      </div>
    <Footer/>
    </div>
  )
}

export default Contactpage
