import React from 'react'
import '../manageraipicture/manageraipicture.css'
import firstpicture from './picture/firstpicture.jpeg'
const Manageraipicture = () => {
  return (
    <div className='container'>
        <div className='general-side'>
            <div className=' firstpicture picture-1'></div>
            <div className='picturetext'>
              <h1>ManagerAI</h1>
            </div>
        </div>
    </div>
  )
}

export default Manageraipicture
