import React from 'react';
const MapComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='google-map'>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.344026560231!2d19.03284751561093!3d47.45883907917242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc2b2bb270c5%3A0x9ddae7016d1b1c7f!2sSpirit%20Centre%2C%20Feh%C3%A9rv%C3%A1ri%20%C3%BAt%20126-128%2C%201116%20Hungary!5e0!3m2!1sen!2s!4v1626053355268!5m2!1sen!2s" 
        width="710" 
        height="424" 
        style={{ border: 0, borderRadius:'12px'}} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  );
};

export default MapComponent;
