import React from 'react'
import './communication.css'
import Map from './map'
import Form from './form'
import './form.css'
const Communication = () => {
  return (
   <div className='communication-container'>
    <div className='communication-general-side'>
        <div className='map'> 
        <h1>Location</h1>
        <Map/>
        </div>
        
        <div className='login'>
        <h1>Contact</h1>
            <div className='Contact'>
                <Form/>
            </div>
        </div>
    </div>
   </div>
  )
}

export default Communication
