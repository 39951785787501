import React from 'react';
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Whoweare from '../../components/about/whoweare'

const Aboutpage = () => {

  return (
    <div>
      {/* About */}
      <Header/>
        <Whoweare/>
      <Footer/>  
    </div>
  )
}

export default Aboutpage
