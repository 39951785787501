import React from 'react'
import '../home/home.css'
import Communication from '../../components/communication/communication'
import Carousel from '../../components/carouselcomponents/carouselimages/carousel'
import FAQ from '../../components/faq/faq'
import Success from '../../components/success/success'
import DepartmanOfKuaralabs from '../../components/departmanOfKuaralabs/departmanOfKuaralabs'
import ProjectKuaralabs from '../../components/projectKuaralabs/projectKuaralabs'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import Intro from '../../components/intro/intro'
import Globecomponent from '../../components/globe/globe.jsx'
import SocialCards from '../../components/socialcards/socialcards.jsx'

const Homepage = () => {
  return (
    <div>
    <Header/>
    <Intro/>
    <FAQ/>
    <Success/> 
    {/* <DepartmanOfKuaralabs/> */}
    <div className="title">
    <h1>Projects of Kuaralabs</h1>
    <p>take a brief look_</p>
    </div>
    <ProjectKuaralabs/>
    {/*<SocialCards/>*/}
    <Communication/>
    <Footer/>
    </div>
  )
}

export default Homepage
