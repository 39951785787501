import React from 'react'
import '../header/header.css'
import '../../pages/home/home'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className='container'>
      <header>
        <div className='navbar'>
          <div className='general-side'>
            <div className='logo'>
              <a href='/'>
                <img src="/starbgremoved.png" alt="Logo" style={{ width: '150px', height: 'auto', justifySelf: 'center' }} />
              </a>
            </div>
            <div className='contents'>
              <ul>
                <Link to='/'><li>Home</li></Link>
                <Link to='/projects'><li>Projects</li></Link>
                <Link to='/departmants'><li>Departments</li></Link>
                <Link to='/about'><li>About</li></Link> 
                <Link to='/contact'><li>Contact</li></Link>
              </ul>
            </div>
            <div className='begin'>
              <a href='/contact'><button>Contact</button></a>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
