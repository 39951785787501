import React from 'react'
import Manageraipicture from '../../components/managerai/manageraipicture/manageraipicture'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import Manageraiinfo from '../../components/managerai/manageraiinfo/ manageraiinfo'

const Managerai = () => {
  return (
    <div className='bg'>
        <Header/>
        <Manageraipicture/>
        <Manageraiinfo/>
        <Footer/>
    </div>
  )
}

export default Managerai
