import React, { useState } from 'react';
import './form.css'
const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className='forms'>
            <div className='name'>
                <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder='name'
                required
                min={2}
                />
            </div>

            <div className='surname'>
                <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                placeholder='surname'
                required
                min={2}
                />
            </div>

            <div className='number'>
                <input
                type="text"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                placeholder='number'
                required
                />
            </div>
                
            <div>
                <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder='email'
                required
                />
            </div>
            
            <div className='message'>
                <input
                type="message"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder='message'
                required
                />
            </div>
        <button type="submit"><a href='#'> Send </a></button>  
      </div>
    </form>
  );
};

export default Form;
