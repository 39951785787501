import React from 'react'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import { Result } from 'antd';

const Fivehundredpage = () => {
  return (
    <>
      {/* 500 */}
      <Header/>
      <Result
        status="500"
        title={<span style={{ color: '#8E8E93' }}>500</span>}
        subTitle={<span style={{ color: '#8E8E93' }}>Sorry, something went wrong.</span>}
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'calc(100vh - 100px)',
          }}
      />
      <Footer/>
    </>
  )
}

export default Fivehundredpage
