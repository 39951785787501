import React from 'react'
import '../manageraiinfo/manageraiinfo.css'
import secondpicture from '../manageraiinfo/picture/secondpicture.jpeg'
const  Manageraiinfo = () => {
  return (
    
    <div className='container managerai-info-container'>
        <div className='general-side mt-4 colm-revers'>
                <div className='secondpicture  picture-2 '></div>
                <div className='infotext'>
                    <h2>ManagerAI</h2>
                    <p>Sonoma sayesinde İnstagram ile internette daha hızlı, daha kolay ve daha güvenli bir şekilde gezinin. İnstagram deki yenilikler arasında profiller, web uygulamaları, gelişmiş Özel Dolaşma ve daha birçok özellik yer alıyor.</p>
                    <a href='#'><button>Go to Team</button></a>
                </div>
        </div>
    </div>
  )
}

export default  Manageraiinfo
