import "./App.css";
import Managerai from "./pages/managerai/managerai";
import Aboutpage from "./pages/about/about";
import Homepage from "./pages/home/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Fourhundredthreepage from "./pages/403/403";
import Fourhundredpage from "./pages/404/404";
import Fivehundredpage from "./pages/500/500";
import Contactpage from "./pages/contact/contact";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/managerai" element={<Managerai />} />
          <Route path="/403" element={<Fourhundredthreepage />} />
          <Route path="/404" element={<Fourhundredpage />} />
          <Route path="/*" element={<Fourhundredpage />} />
          <Route path="/500" element={<Fivehundredpage />} />
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/projects" element={<Fivehundredpage />} />
          <Route path="/departmants" element={<Fivehundredpage />} />
          <Route path="/cognitio" element={<Fivehundredpage />} />
          <Route path="/kuaraui" element={<Contactpage />} />
          <Route path="/kbps" element={<Fivehundredpage />} />
          <Route path="/startupper" element={<Fivehundredpage />} />
          <Route path="/managerai" element={<Fivehundredpage />} />
          <Route path="/poiso" element={<Fivehundredpage />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
