import React from 'react';
import Slider from './slider';
import Carousel from '../carouselcomponents/carouselimages/carousel';

const images = [
  'https://via.placeholder.com/600x400?text=1',
  'https://via.placeholder.com/600x400?text=2',
  'https://via.placeholder.com/600x400?text=3',
];

function ProjectKuaralabs() {
  return (
    <div className="App">
      <Carousel/>
    </div>
  );
}

export default ProjectKuaralabs;
