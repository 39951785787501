import React from 'react';
import './infinitescroll.css';

const Infinitescroll = () => {
  return (
    <div className="wrappers">
      <div className="wrapper">
        <div className="itemLeft item1">Startupper</div>
        <div className="itemLeft item2">Cognitio</div>
        <div className="itemLeft item3">Syscall</div>
        <div className="itemLeft item4">KBPS</div>
        <div className="itemLeft item5">KuaraCSS</div>
        <div className="itemLeft item6">Poiso</div>
        <div className="itemLeft item7">Agredrog</div>
        <div className="itemLeft item8">ManagerAI</div>
      </div>
      <div className="wrapper">
        <div className="itemRight item1">PTX Food</div>
        <div className="itemRight item2">Ostim Energy</div>
        <div className="itemRight item3">Auravenom</div>
        <div className="itemRight item4">PTX Food</div>
        <div className="itemRight item5">Ostim Energy</div>
        <div className="itemRight item6">Auravenom</div>
        <div className="itemRight item7">PTX Food</div>
        <div className="itemRight item8">Ostim Energy</div>
      </div>
    </div>
  );
}

export default Infinitescroll;
