import React, { useState, useEffect } from "react";
import "./faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Faq = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const faqData = [
    {
      question: "What is Kuaralabs and what services does it provide?",
      answer:
        "Kuaralabs is a multi-laboratory company specializing in various disciplines. We offer services in Microbiology & Genetics, Computer Sciences, Mechatronics, Management Consultancy, Medical, Web & Mobile Development, Agricultural Technologies, and Artificial Intelligence.",
      imageUrl: "./pictureFaq/faqPicture1.jpeg",
    },
    {
      question: "What is the mission of Kuaralabs?",
      answer:
        "The mission of Kuaralabs is to leverage the power of technology and science to deliver innovative solutions to our clients. With specialized teams in each field, we aim to provide high-quality and cutting-edge solutions.",
      imageUrl: "./pictureFaq/faqPicture2.jpg",
    },
    {
      question: "Does Kuaralabs offer customized solutions for specific industries?",
      answer:
        "Yes, Kuaralabs specializes in providing tailored solutions across multiple industries. Whether you're in healthcare, agriculture, technology, or management, we have expertise to address your unique needs.",
      imageUrl: "./pictureFaq/faqPicture3.jpg",
    },
    {
      question: "Where is Kuaralabs located?",
      answer:
        "Kuaralabs is headquartered in Hungary, with a global presence through our network of laboratories and consulting services.",
      imageUrl: "./pictureFaq/faqPicture4.jpg",
    },
    {
      question: "How can I contact Kuaralabs for more information?",
      answer:
        "You can reach out to us through our website contact form or directly via email at contact@kuaralabs.com. Our team will be happy to assist you with any inquiries or requests.",
      imageUrl: "./pictureFaq/faqPicture5.jpg",
    },
  ];

  const toggleFAQ = (index) => {
    if (isOpen && currentItem === index) {
      setIsOpen(false);
    } else {
      setCurrentItem(index);
      setIsOpen(true);
    }
  };

  return (
    <div className="faq-container">
      <div className="faq-content">
        <h1>Who is Kuaralabs?</h1>
        <div className="faq-list">
          {faqData.map((faq, index) => (
            <div
              className={`faq ${isOpen && currentItem === index ? "open" : ""}`}
              key={index}
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="faq-question">
                {faq.question}
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="arrow"
                  style={{
                    color: isOpen && currentItem === index ? "#35C587" : "#fff",
                    transform: isOpen && currentItem === index ? "rotate(-180deg)" : "rotate(0)",
                  }}
                />
              </h3>
              {isOpen && currentItem === index && (
                <p className="faq-answer">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="faq-image">
        {faqData.map((faq, index) => (
          <img
            key={index}
            src={faq.imageUrl}
            alt={`FAQ Visual ${index + 1}`}
            className={currentItem === index ? "visible" : "hidden"}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
