import React, { useEffect, useRef } from 'react';
import './whoweare.css';

const Whoweare = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const elements = container.querySelectorAll('.animate');

    elements.forEach((element, index) => {
      const delay = index * 200; // Adjust delay as needed
      element.style.transitionDelay = `${delay}ms`;
      element.classList.add('fadeIn');
    });
  }, []);

  return (
    <div ref={containerRef} className="container whoweare-container">
      <h1 className="animate">Who is Kuaralabs?</h1>
      <p className="animate">
        Kuaralabs is a visionary company that integrates diverse fields of science and technology to provide innovative solutions and services. Founded on the principles of excellence and innovation, we are committed to pushing the boundaries of what is possible and delivering outstanding results to our clients.
      </p>
      <h3 className="animate">Our Expertise</h3>
      <ul className="animate">
        <li><strong>Microbiology & Genetics:</strong> Our team of scientists is at the forefront of research and development in microbiology and genetics, offering cutting-edge solutions for health and environmental challenges.</li>
        <li><strong>Computer Sciences:</strong> From software development to advanced computing technologies, our computer science experts are dedicated to creating powerful and efficient digital solutions.</li>
        <li><strong>Mechatronics:</strong> We specialize in the integration of mechanical, electronic, and software engineering to develop sophisticated and reliable mechatronic systems.</li>
        <li><strong>Management Consultancy:</strong> Our consultancy services help organizations improve their performance and achieve their strategic goals through expert advice and innovative strategies.</li>
        <li><strong>Medical Services:</strong> We provide a range of medical services, including research, diagnostics, and treatment solutions, ensuring the highest standards of care and innovation.</li>
        <li><strong>Web & Mobile Development:</strong> Our development team creates responsive, user-friendly web and mobile applications that meet the needs of modern businesses and consumers.</li>
        <li><strong>Agricultural Technologies:</strong> We are dedicated to enhancing agricultural productivity and sustainability through the development and implementation of advanced technologies.</li>
        <li><strong>Artificial Intelligence:</strong> Our AI specialists harness the power of machine learning and artificial intelligence to develop intelligent systems that drive efficiency and innovation.</li>
      </ul>
      <h3 className="animate">Our Mission</h3>
      <p className="animate">
        Our mission at Kuaralabs is to bridge the gap between science and industry by fostering innovation, collaboration, and excellence. We strive to deliver groundbreaking solutions that address the complex challenges of today and anticipate the needs of tomorrow.
      </p>
      <h3 className="animate">Our Values</h3>
      <p className="animate"><strong>Innovation:</strong> We are committed to continuous innovation, constantly seeking new ways to solve problems and improve our offerings.</p>
      <p className="animate"><strong>Excellence:</strong> We uphold the highest standards in everything we do, ensuring quality, reliability, and integrity in our services.</p>
      <p className="animate"><strong>Collaboration:</strong> We believe in the power of collaboration, working closely with our clients, partners, and each other to achieve common goals.</p>
      <p className="animate"><strong>Sustainability:</strong> We are dedicated to promoting sustainability in all our practices, contributing to a better and more sustainable future.</p>
      <h3 className="animate">Why Choose Kuaralabs?</h3>
      <p className="animate">
        Choosing Kuaralabs means partnering with a team of passionate and dedicated professionals who are committed to your success. We leverage our diverse expertise and innovative approach to deliver solutions that are not only effective but also transformative.
      </p>
      <p className="animate">
        Join us on our journey to explore new frontiers and make a positive impact on the world. Together, we can achieve extraordinary things.
      </p>
    </div>
  );
};

export default Whoweare;
