import React from 'react'
import '../footer/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
   <div className='container'>
      <footer className='footer'>
          <div className='general-side footer-content'>
            <h2>Kuaralabs</h2>
            <div className='contents'>
              <div className='supporting '>
                <h2>Kuaralabs Teams</h2>
                  <ul>
                    <li>Management Team</li>
                    <li>Web & Mobile Team</li>
                    <li>Artificial Intelligence Team</li>
                    <li>IoT & Embedded Systems Team</li>
                    <li>Mechatronics Team</li>
                    <li>Biotechnics & Agriculture Team</li>
                  </ul>
              </div>
              <div className='forinstitutional '>
                <h2>For Corporate Clients</h2>
                  <ul>
                    <a href='#'><li>Our Corporate Software</li></a>
                    <a href='#'><li>For Universities and R&D Centers</li></a>
                    <a href='#'><li>Events</li></a>
                    <a href='#'><li>Catalog</li></a>
                    <a href='#'><li>Contact</li></a>
                  </ul>
              </div>
              <div className='project '>
                <h2>Projects</h2>
                  <ul>
                   <a href='/kuaracss'><li>KuaraCSS</li></a>
                   <a href='/agredrog'><li>Agredrod</li></a>
                   <a href='/kbps'><li>KBPS</li></a>
                   <a href='/poiso'><li>Poiso</li></a>
                   <a href='/managerai'><li>ManagerAI</li></a>
                   <a href='/startupper'><li>Startupper</li></a>
                   <a href='/cognitio'><li>Cognitio</li></a>
                  </ul>
              </div>
            </div>
          </div>

          <p id='store'>For issues with your product, contact your seller or email us at <a href='mailto:contact@kuaralabs.org'>contact@kuaralabs.org</a>.</p>
          <p id='Copyright'>Copyright © 2024 Kuaralabs Kft. All rights reserved.</p>

          <div className='cookies-social-side'>
            <div className='general-side footer-content'>
              <div className='cookies'>
                <ul>
                 <a href='#'> <li>Privacy Policy</li></a>
                 <a href='#'> <li>Cookie Usage</li></a>
                 <a href='#'> <li>Terms of Use</li></a>
                 <a href='#'> <li>Sales and Refunds</li></a>
                 <a href='#'> <li>Legal Information</li></a>
                </ul>
              </div>
              <div className='social-media'>
            <a href='https://www.instagram.com/kuaralabs/'>  <FontAwesomeIcon icon={faInstagram} size='xl' style={{color: "#35c587", marginLeft:'10px',marginTop:'10px', padding:'8px', borderRadius:'10px', backgroundColor:'#2C2C2C'}}/></a>
            <a href='https://www.linkedin.com/company/kuaralabs/'>  <FontAwesomeIcon icon={faLinkedinIn} size='xl' style={{color: "#35c587",marginLeft: '10px',marginTop:'10px',padding:'8px', borderRadius:'10px', backgroundColor:'#2C2C2C'}}/></a>
            <a href='https://www.youtube.com/@Kuaralabs'>  <FontAwesomeIcon icon={faYoutube}size='xl' style={{color: "#35c587", marginLeft: '10px',marginTop:'10px', padding:'8px', borderRadius:'10px', backgroundColor:'#2C2C2C'}} /></a>
            <a href='https://github.com/Kuaralaboratories'>  <FontAwesomeIcon icon={faGithub} size='xl' style={{color: "#35c587", marginLeft: '10px', marginTop:'10px',padding:'8px', borderRadius:'10px', backgroundColor:'#2C2C2C'}}/></a>
            
        {/* ICONS TO HAVE THEIR COLORS REVERSED ON HOVER */}
              </div>
            </div>
          </div>
      </footer>
   </div>
  )
}

export default Footer
