import React, { useState } from "react";
import "./carousel.css"; // Assuming your CSS file is named carousel.css

const Carousel = () => {
  const [position, setPosition] = useState(1); // State to manage the position

  const handlePrev = () => {
    setPosition(position > 1 ? position - 1 : 1);
  };

  const handleNext = () => {
    setPosition(position < 12 ? position + 1 : 12);
  };

  return (
    <main className="carousel-main">
      <div className="carousel-container">
        {/* Navigation buttons */}
        <button className="nav-button left" onClick={handlePrev}>{"<"}</button>
        <button className="nav-button right" onClick={handleNext}>{">"}</button>

        {/* Carousel */}
        <div id="carousel" style={{ "--position": position }}>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
          <div className="item"></div>
        </div>
      </div>
    </main>
  );
};

export default Carousel;
